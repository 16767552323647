<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "roles",
              "add-permission-to-role",
              "add-permission-to-role"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('roles', 'add-permission-to-role', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <!-- User menu permissions -->
    <span
      v-if="containerTypeId == 1"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.user.menu-access") }}</span
    >
    <ul class="clebex-item-section" v-if="containerTypeId == 1 && userMenu">
      <li
        class="clebex-item-section-item"
        v-for="permission in userMenu"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter((item) =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>
          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
          </div>
        </div>
      </li>
    </ul>
    <!-- User restrictions -->
    <span
      v-if="containerTypeId == 1"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.user.restrictions") }}</span
    >
    <ul
      class="clebex-item-section"
      v-if="containerTypeId == 1 && userRestrictions"
    >
      <li
        class="clebex-item-section-item"
        v-for="permission in userRestrictions"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter((item) =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>
          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
          </div>
        </div>
      </li>
    </ul>
    <!-- User permissions -->
    <span
      v-if="containerTypeId == 1"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.user.permissions") }}</span
    >
    <ul
      class="clebex-item-section"
      v-if="containerTypeId == 1 && userPermissions"
    >
      <li
        class="clebex-item-section-item"
        v-for="permission in userPermissions"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter((item) =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>
          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
          </div>
        </div>
      </li>
    </ul>
    <!-- Resource permissions -->
    <span
      v-if="containerTypeId == 4"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.resource.permissions") }}</span
    >
    <ul
      class="clebex-item-section"
      v-if="containerTypeId == 4 && resourcePermissions"
    >
      <li
        class="clebex-item-section-item"
        v-for="permission in resourcePermissions"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter((item) =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>

          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
            <template v-if="permission.permission_type.type === 'NUMERICAL'">
              <input
                type="text"
                v-model="permission.value"
                @change="updateSelectedPermission(permission)"
                :placeholder="
                  displayLabelName(
                    'roles',
                    'add-permission-to-role',
                    'enter-value'
                  )
                "
              />
            </template>
          </div>
        </div>
      </li>
    </ul>
    <!-- Resource time related actions -->
    <span
      v-if="containerTypeId == 4"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.resource.time-related-actions") }}</span
    >
    <ul
      class="clebex-item-section"
      v-if="containerTypeId == 4 && resourceTimeRelatedActions"
    >
      <li
        class="clebex-item-section-item"
        v-for="permission in resourceTimeRelatedActions"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter((item) =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>

          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
            <template v-if="permission.permission_type.type === 'NUMERICAL'">
              <input
                type="text"
                v-model="permission.value"
                @change="updateSelectedPermission(permission)"
                :placeholder="
                  displayLabelName(
                    'roles',
                    'add-permission-to-role',
                    'enter-value'
                  )
                "
              />
            </template>
          </div>
        </div>
      </li>
    </ul>
    <!-- Data access (CRUD) -->
    <span
      v-if="containerTypeId == 1"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.user.data-access") }}</span
    >
    <span
      v-if="containerTypeId == 4"
      class="permission-title"
      style="margin-top: 20px; margin-left: 15px;"
      >{{ displayLabelName("permissions.resource.data-access") }}</span
    >
    <ul class="clebex-item-section" v-if="crud">
      <li
        class="clebex-item-section-item"
        v-for="permission in crud"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter((item) =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>
          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
            <template v-if="permission.permission_type.type === 'CRUD'">
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-create-${permission.id}`"
                  @change="updateSelectedPermission(permission)"
                  v-model="permission.create"
                />
                <label :for="`permission-create-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "roles",
                      "add-permission-to-role",
                      "create"
                    )
                  }}</label
                >
              </div>
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-read-${permission.id}`"
                  @change="updateSelectedPermission(permission)"
                  v-model="permission['read']"
                />
                <label :for="`permission-read-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName("roles", "add-permission-to-role", "read")
                  }}</label
                >
              </div>
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-update-${permission.id}`"
                  v-model="permission.update"
                  @change="updateSelectedPermission(permission)"
                />
                <label :for="`permission-update-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "roles",
                      "add-permission-to-role",
                      "update"
                    )
                  }}</label
                >
              </div>
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-delete-${permission.id}`"
                  @change="updateSelectedPermission(permission)"
                  v-model="permission.delete"
                />
                <label :for="`permission-delete-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "roles",
                      "add-permission-to-role",
                      "delete"
                    )
                  }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
export default {
  name: "RolesAddPermissions",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      hiddenPermissions: [
        "AUTHORIZE",
        "PLAN_COLORS",
        "EVENTS",
        "MESSAGES",
        "NOTIFICATIONS",
        "RESOURCE_LAYOUTS",
        "SURVEY",
        "PASSWORD_STRUCTURE",
        "HOLIDAYS",
        "TOKEN_STATEMENT",
        "GDPR",
        "MANAGE_DAY_PERIODS",
        "TRANSFER_USER_EVENTS",
        "DEVICES_CONTAINERS",
        "CONTAINERS_EXPLORER",
        "MEDIA_CONTAINERS",
        "5DMAPS_CONTAINERS",
        "REPORTS_CONTAINERS",
        "VENDORS_CONTAINERS",
        "USERS_PRIORITIES",
        "PIN_CODE_STRUCTURE",
        "DEVICES_ROLES",
        "VISITORS_ROLES",
        "VENDORS_ROLES",
        "REPORTS_ROLES",
        "5DMAPS_ROLES",
        "MEDIA_ROLES",
        "MAXIMUM_DURATION_OF_BOOKING",
        "MAX_BOOKINGS_PER_DAY",
        "MAX_BOOKINGS_PER_FORTNIGHT",
        "MAX_BOOKINGS_PER_MONTH",
        "MAX_BOOKINGS_PER_QUARTER",
        "MAX_BOOKINGS_PER_YEAR",
        "MINIMUM_DURATION_OF_BOOKING",
        "MINUTES_FOR_BOOKING_IN_ADVANCE",
        "HOURS_FOR_BOOKING_IN_ADVANCE",
        "DAYS_FOR_BOOKING_IN_ADVANCE",
        "WEEKS_FOR_BOOKING_IN_ADVANCE",
        "MONTHS_FOR_BOOKING_IN_ADVANCE",
        "ONLY_SAME_DAY_BOOKING",
        "DISTANCE",
        "BOOKING_ALLOWED_MINUTES_BEFORE",
      ],
      userPermissionsRestrictions: [
        "MY_BOOKINGS",
        "MY_SERVICES",
        "MY_VISITORS",
      ],
      userPermissionsPermissions: [
        "BOOKINGS",
        "APPROVE",
        "HAS_PRIORITY",
        "SERVICE_STATUS",
      ],
      resourcePermissionsTimeRelatedActions: [
        "AUTO_RELEASE",
        "EXPIRING_WARNING",
        "PRE_CHECK_IN",
      ],
    };
  },
  computed: {
    ...mapState("containers", ["containerAccessTypes"]),
    ...mapState("roles", [
      "permissionsList",
      "selectedPermissions",
      "permissionsInRole",
    ]),
    containerTypeId() {
      const { containerAccessTypes, containerType } = this;
      if (
        containerType &&
        containerAccessTypes &&
        containerAccessTypes.data &&
        containerAccessTypes.data.length
      ) {
        const filtered = containerAccessTypes.data.filter(
          (item) => item.type === containerType
        );
        if (filtered && filtered.length) {
          return filtered[0].id;
        }
        return null;
      }
      return null;
    },
    permissions() {
      const query = this.searchQuery;
      let finalPermissions = [];
      if (
        this.permissionsList &&
        this.permissionsList.data &&
        this.permissionsList.data.length
      ) {
        if (query && query.length > 1) {
          finalPermissions = this.permissionsList.data.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          finalPermissions = this.permissionsList.data;
        }

        // finalPermissions = this.sortPermissions(finalPermissions);
      }
      return finalPermissions;
    },
    userMenu() {
      return this.permissions
        .filter(
          (p) =>
            p.permission_type.type === "LOGICAL" &&
            !this.hiddenPermissions.includes(p.permission) &&
            !this.userPermissionsPermissions.includes(p.permission) &&
            !this.userPermissionsRestrictions.includes(p.permission)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    userRestrictions() {
      return this.permissions
        .filter(
          (p) =>
            p.permission_type.type === "LOGICAL" &&
            !this.hiddenPermissions.includes(p.permission) &&
            this.userPermissionsRestrictions.includes(p.permission)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    userPermissions() {
      return this.permissions
        .filter(
          (p) =>
            p.permission_type.type === "LOGICAL" &&
            !this.hiddenPermissions.includes(p.permission) &&
            this.userPermissionsPermissions.includes(p.permission)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    resourcePermissions() {
      let resPerm = this.permissions
        .filter(
          (p) =>
            (p.permission_type.type === "LOGICAL" ||
              p.permission_type.type === "NUMERICAL") &&
            !this.hiddenPermissions.includes(p.permission) &&
            !this.userPermissions.includes(p.permission) &&
            !this.userPermissionsRestrictions.includes(p.permission) &&
            !this.resourcePermissionsTimeRelatedActions.includes(p.permission)
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      if (resPerm.length > 0) {
        const restrictDays = [
          "RESTRICT_DECLARING_ON_MONDAY",
          "RESTRICT_DECLARING_ON_TUESDAY",
          "RESTRICT_DECLARING_ON_WEDNESDAY",
          "RESTRICT_DECLARING_ON_THURSDAY",
          "RESTRICT_DECLARING_ON_FRIDAY",
          "RESTRICT_DECLARING_ON_SATURDAY",
          "RESTRICT_DECLARING_ON_SUNDAY",
        ];

        for (const day of restrictDays) {
          let index = resPerm.findIndex((p) => p.permission === day);
          let [d] = resPerm.splice(index, 1);
          resPerm.push(d);
        }
      }

      return resPerm;
    },
    resourceTimeRelatedActions() {
      return this.permissions
        .filter(
          (p) =>
            (p.permission_type.type === "LOGICAL" ||
              p.permission_type.type === "NUMERICAL") &&
            !this.hiddenPermissions.includes(p.permission) &&
            this.resourcePermissionsTimeRelatedActions.includes(p.permission)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    crud() {
      return this.permissions
        .filter(
          (p) =>
            p.permission_type.type === "CRUD" &&
            !this.hiddenPermissions.includes(p.permission)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions("roles", [
      "getPermissionsList",
      "setSelectedPermission",
      "updateSelectedPermission",
    ]),
  },
  watch: {
    containerTypeId: {
      immediate: true,
      handler() {
        if (this.containerTypeId) {
          this.getPermissionsList({
            access_type_ids: [this.containerTypeId],
            includes: ["permission_type", "permission_category"],
          });
        }
      },
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
    containerType: {
      required: true,
      type: String,
    },
  },
};
</script>
<style>
.permission-title {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: -15px !important;
  margin-left: 20px !important;
}
</style>
